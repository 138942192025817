import React, { forwardRef,useState, useEffect, useRef } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import LocalIcon from "../../assets/icons";

import { bookedRedx } from "../../helper/customSlice";
import callApi from '../../services/api/apiClient';
import LoadingComponent from '../../components/loading/LoadingComponent';
import dayjs from 'dayjs';

const dateFormat = (date,time) =>{
  const parsedTime = dayjs(time?.toUpperCase(), 'hh:mm A');
  return dayjs(date).hour(parsedTime.hour()).minute(parsedTime.minute());
}

const Booked = forwardRef(({setOpen,...props},ref) => {
  const elementRef = useRef()
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [delta, setDelta] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: '100%', height: 0 ,pre_Y:0});
  const [oldSize, setOldSize] = useState({ width: '100%', height: 0 ,pre_Y:0});
  const [oldPosition,setOldPosition] = useState({ x: 0, y: 0 })
  const [scrollY,setScrollY] = useState(window.scrollY)
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [dragStartPosition,setDragStartPosition] = useState({ x: 0, y: 0 })
  const [mouseUpTriggered, setMouseUpTriggered] = useState(false);
  const [time, setTime] = useState({ fromTime: props?.fromTime.toLowerCase(), toTime: props?.toTime.toLowerCase() })
  let defaultHeight = 52
  
  // Redux
  const dispatch = useDispatch();  
  const prevData = useSelector((state) => state?.bookedRedx?.bookedRedxRequest?.info)

  // API
  const adminId = useSelector((state)=> state?.auth?.login?.data?.data?.user?.id)
  const approvedId = props?.approved_by?.find((approver)=> approver.id === adminId)

  
  let element = document.getElementById(props.id)

  const handelStartPosition = (time) =>{
    var hour = parseInt(time[1], 10);
    var minute = parseInt(time[2], 10);
    var period = time[3].toLowerCase()

    if( period === 'am'){
      if(hour === 12){
        hour = 0
      }        
    }
    else{
      if(hour !== 12){
        hour = hour + 12
      }
        
    }
     
    let startPosition =( hour * defaultHeight) + minute * defaultHeight / 60
    return startPosition
  }
  
  useEffect(()=>{ 
    const startTime = props?.fromTime
    const endTime = props?.toTime
       
    setTime({fromTime:startTime?.toLowerCase(),toTime:endTime?.toLowerCase()})
    
     var regex = /^(\d{1,2}):(\d{2})\s*([apAP][mM])$/;

     var startMatch = startTime?.match(regex);
     var endMatch = endTime?.match(regex);

     if (startMatch && endMatch) {
        let startTime = handelStartPosition(startMatch)
        let endPosition = handelStartPosition(endMatch) - startTime - 2
        if(endPosition < 0){
          const bookedcontainer = document.getElementsByClassName("book-create-container")
          endPosition = bookedcontainer[0].offsetHeight - startTime - 3
        }       
        setPosition({x:position.x,y:startTime})

        setSize((preSize)=>{
          const newSize = {
            width:preSize.width,
            height:endPosition,
            pre_Y:preSize.pre_Y
          }
          return newSize
        })
     }
      else {
        //  console.log("Invalid time format");
     }
  },[props?.fromTime,props?.toTime])


  useEffect(()=>{
    callApi("users/admin")
      .withKeyParameter({userId:adminId})
      .executeDispatch();
  },[])
   
  const checkOverlap = (start,end,id) =>{
    const filterId = prevData?.filter(filter => filter?.id !== Number(id) )
    
    const filterData = filterId?.some((filter)=>
      {
        // Convert time strings to Date objects
        const startTime = dateFormat(filter.book_date,filter.start_time);
        const endTime = dateFormat(filter.book_date,filter.end_time);
        
        // Convert start and end times to Date objects
        const checkStartTime = dateFormat(props?.book_date,start);
        const checkEndTime = dateFormat(props?.book_date,end);
        
        // Compare the converted Date objects
        return startTime < checkEndTime && endTime > checkStartTime;
      }
    )
    return filterData 
  }
  
  useEffect(() => {   
    // document?.addEventListener('touchmove', handleTouchMove, { passive: false });
    const mouseUpHandler = (e) => {
      setMouseUpTriggered(!mouseUpTriggered);
    };
    const touchmoveHandler = (e) => {
      setMouseUpTriggered(false)
      handleTouchMove(e)
    };

    const touchEndHandler = (e) => {
      if (mouseUpTriggered && getY(e) !== dragStartPosition.y) {
        e.stopPropagation();
        return;
      }
    };

    element?.addEventListener("touchmove", touchmoveHandler, false);
    element?.addEventListener("mouseup", mouseUpHandler, false);
    element?.addEventListener("touchend", touchEndHandler, false);
    return () => {
      element?.removeEventListener('touchmove', touchmoveHandler);
      element?.removeEventListener("mouseup", mouseUpHandler);
      element?.removeEventListener("touchend", touchEndHandler);
    };
}, [isDragging,isResizing,mouseUpTriggered]);

  const handleTimeUpdate = (elementId) => { 
    props?.setLoadingCreate(false)
    const participantsId = props?.participants.filter((filter) => filter.id).map((participant) => participant.id);
    if(elementId == props?.id && !isDragging || !isResizing){
        const updateData = {
            title: props?.name,
            note: props?.note,
            book_date: props?.book_date,
            start_time: time.fromTime,
            end_time: time.toTime,
            facility_id: props?.facility_id?.id,
            locations:props?.locations,
            book_by: props?.book_by?.id,
            participants: participantsId
        }
        if(Object.keys(updateData).length !== 0){
          callApi("booked/bookedUpdate")
          .withKeyParameter({bookedId:props.id})
          .withBody(updateData)
          .loadingGroup("up"+props.id)
          .executeDispatch()
          .then((res)=>{
            props.setCreateData(res)
            if(res?.data){
              const data = res?.data
              const updatedDataRex = {
                  id:data?.id,
                  title: data?.title,
                  note: data?.note,
                  book_date: data?.book_date,
                  start_time: data?.start_time,
                  end_time: data?.end_time,
                  facility_id: data?.facility_id,
                  locations:data?.locations,
                  book_by: data?.book_by,
                  participants: data?.participants,
                  status:data?.status
              };
              dispatch(bookedRedx?.actions.updateData([updatedDataRex]));
            }
          });   
        }
      }
    }
  const handleTouchStart = (e) => { 
    const isResizeHandle =  e.target.classList.contains("resize-handle") ||
                            e.target.classList.contains("resize-design") ||
                            e.target.classList.contains("resize");
    
    setScrollY(window.scrollY)
    setIsMouseOver(true)
    setMouseUpTriggered(!mouseUpTriggered)
    if(adminId === props?.book_by?.id){
      setIsDragging(!isResizeHandle);
      setIsResizing(isResizeHandle);

      setPosition({ x: getX(e), y: getY(e) });
      setDragStartPosition({ x: getX(e), y: getY(e) })
      setDelta({ x: getX(e) - element?.offsetLeft, y: getY(e) - element?.offsetTop + 1 }); 
      setSize(
        {
          width: size.width,
          height: size.height,
          pre_Y : getY(e)
        });
        setOldSize(
          {
            width: size.width,
            height: size.height,
            pre_Y : getY(e)
          });
    }
    
  };

  const handleTouchMove = (e) => { 
    const bookedCreate = document.getElementsByClassName("book-create-container")
    const bottom = (element?.offsetTop + 1 + element?.offsetHeight) >= bookedCreate[0].offsetHeight
    if (isDragging) {  
      e.preventDefault();     
      if(position.y <= getY(e)){
        if(bottom){
          return
        }
      }

      if ((getY(e) < delta.y)) {
        return
      }      
        setPosition({ x: getX(e), y: getY(e) });
      //Time
      handelTime(getY(e) - delta.y,size.height)

    } else if (isResizing) {
      // if(window.innerWidth < 992){
      //   e.preventDefault();
      // }
      e.preventDefault(); 
      const newWidth = size.width;
      let newHeight = size.height;
      if(size.pre_Y != 0){
        newHeight = size.height + (getY(e) - size.pre_Y );
      }
      
      // newHeight = newHeight > 15 ? newHeight : 13;
      if (newHeight + element.offsetTop > bookedCreate[0].offsetHeight) {
        return
      }

      setSize(
        {
          width: newWidth,
          height: newHeight,
          pre_Y : getY(e)
        });
      handelTime(element.offsetTop - 1,newHeight)
    }
  };

  const handleTouchLeave = (e) =>{
    if(isMouseOver){
      handleTouchEnd(e)
    }
  }
  const handleTouchEnd = (e) => {
    setIsMouseOver(false)
    // setMouseUpTriggered(false)
    if(!isMouseOver){
      if(!isDragging && !isResizing){
        return false
      }
    }
    else{
      if(scrollY === window.scrollY){
        setIsDragging(false);
        setIsResizing(false);
        setMouseUpTriggered(false)
        // for onTouchEnd(Mobile) // NaN
        if (isNaN(getY(e))) {
          setPosition({x:position.x,y:(position.y - delta.y)})
          return;
        }
        else{
          if(adminId !== props?.book_by?.id){
            handelClick()
          }
          else{ 
            if(getY(e)=== dragStartPosition.y){
              handelClick()
              setPosition({x:position.x,y:(position.y - delta.y)})
            } 
            else{
              setPosition({x:position.x,y:(position.y - delta.y)})
              setSize({width:size.width,height:size.height,pre_Y:size.pre_Y})
              if(checkOverlap(time.fromTime,time.toTime,element?.id)){
                if(oldPosition.y === 0){
                  const firstOldPosition = dragStartPosition.y - delta.y
                  setPosition({ x: getX(e), y: firstOldPosition });
                  handelTime(firstOldPosition,size.height)
                  props.setCreateData(`The booking is not available in (${time.fromTime} - ${time.toTime}) yet.`)
                }
                else{
                  setPosition({ x: getX(e), y: oldPosition.y });
                  handelTime(oldPosition.y,size.height)
                  props.setCreateData(`The booking is not available in (${time.fromTime} - ${time.toTime}) yet.`)
                }
                if(isResizing){
                  setSize({
                    width:oldSize.width,
                    height:oldSize.height,
                    pre_Y:oldSize.pre_Y
                  })
                  handelTime(element.offsetTop - 1,oldSize.height)
                  props.setCreateData(`The booking is not available in (${time.toTime}) yet.`)
                }
              }
              else{
                handleTimeUpdate(element?.id);
                setOldPosition({ x: getX(e), y: (position.y - delta.y) })
                setOldSize({width:size.width,height:size.height,pre_Y:size.pre_Y})
              }
            }
          }
        }
      }
    }
  }; 

  const handelTime = (positionY,height) => {
    let position = Math.floor(positionY)
    // Start Time
    let fromTime = Math.floor(position / defaultHeight)
    let fromMinute = position % defaultHeight
    let startTime = '12 am'
    startTime = Time(fromTime, fromMinute)

    // End Time
    let totalTime = (height + 2) + position
    let toTime = Math.floor(totalTime / defaultHeight)
    let toMinute = totalTime % defaultHeight
    let endTime = '1 am'
    endTime = Time(toTime, toMinute)

    setTime({ fromTime: startTime, toTime: endTime })
  }

  const Time = (time, minute) => {
    let returnTime;
    let unit = time == 24? ' am' :time > 11 ? ' pm' :' am'
    if (time === 0) {
      time = 12
    }
    else if (time > 12) {
      time = time - 12
    }

    // Minute
    if (minute === 13) {
      minute = 15
    }
    else if (minute === 26) {
      minute = 30
    }
    else if (minute === 39) {
      minute = 45
    }



    // Totla Time
    if (minute === 0) {
      returnTime = `${time}:00${unit}`
    }
    else {
      returnTime = `${time}:${minute}${unit}`
    }

    return returnTime;
  }

  const getX = (event) => {
    if (typeof event.touches !== "undefined") {
      const touch = event.touches[0];
      return touch?.clientX;
    } else {
      return event.clientX;
    }
  }

  const getY = (event) => {
    if (event.touches && event.touches.length > 0 && event.touches[0].clientY !== "undefined") {
      const touch = event.touches[0];
      return gridMove(touch?.clientY);
    } 
    else if(event.changedTouches && event.changedTouches.length > 0 && event.changedTouches[0].clientY !== "undefined"){
      return event.changedTouches[0].clientY;
    }
    else {
      return gridMove(event.clientY);
    }
  }

  const gridMove = (position) => {
    position = (position / 13);
    return Math.round(position) * 13;
  }
 
  const handelClick=()=>{
    setOpen(false);
    props.setOpenShow(true);
    props.setPopupId(props.id);
  }
  return (
    <LoadingComponent loadingGroup={"f"} loadingDesign="true">
      <div
        ref={elementRef}
        id={props.id}
        onTouchStart={handleTouchStart}
        // onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleTouchStart}
        onMouseMove={handleTouchMove}
        onMouseLeave={handleTouchLeave}
        onMouseUp ={handleTouchEnd}
        className='booked-container'
        style={{
          touchAction: 'auto',
          width: `${size.width}px`,
          height: `${size.height}px`,
          top: (isDragging || isResizing)? `${position.y - delta.y}px`: `${position.y}px`,
          zIndex: (isDragging || isResizing) ? "12" : "10",
          cursor: isDragging ? "move" : isResizing ? "s-resize" :"auto",
          background:isDragging? "#D4F1F4" : adminId !== props?.book_by?.id? "white" : props.status === 'pending'? "#189AB4":"#05445E",
          border:adminId === props?.book_by?.id? 'none':props.status === 'pending'? "1px solid #189AB4":'1px solid #05445E'
        }}
      >
        
        <LoadingComponent loadingGroup={"up"+props?.id} bookedDesign={"true"}>
          <div className="resize-handle"
               style={{height:isResizing? '55px':'15px', bottom:isResizing? '-35px':'5px'}}>
            <div className='resize-design' 
                style={{
                  background : adminId !== props?.book_by?.id? "transparent" :"transparent"
                }}>
                  <div className='resize'
                        style={{
                          background : adminId !== props?.book_by?.id? "transparent" :"#73FFFF",
                        }}>

                  </div>
            </div>
          </div>
          <div className='booked-data'
            style={
              {
                height: `${size.height}px`,
                display: size.height < 50 ? 'flex' : 'block'
              }
            }>
              <div className='booked-data-title' style={{alignItems:adminId === props?.book_by?.id && 'start'}}>
                <p style={
                  {
                    color: adminId === props?.book_by?.id && isDragging? '#05445E':
                            adminId === props?.book_by?.id? "#ffffff" :
                            props.status === 'pending'? '#189AB4': "#05445E",
                    lineHeight:adminId === props?.book_by?.id && '18px'
                  }
                }>{props.name} </p>
                {
                  adminId !== props?.book_by?.id && (
                    props.status === 'pending' && approvedId? <img src={LocalIcon.AdminWaiting}/> :
                    props.status === 'pending'?
                    <img src={LocalIcon.Waiting}/>
                    :<img src={LocalIcon.Success}/>
                  )
                }
              </div>
              
            <p className='booked-time'
              style={
                {
                  color: adminId === props?.book_by?.id && isDragging? '#05445E':
                        adminId === props?.book_by?.id? "#ffffff" :
                        props.status === 'pending'? '#189AB4': "#05445E"
                }
              }>{time.fromTime} - {time.toTime} [ {props?.book_by?.name} ]</p>

          </div>
        </LoadingComponent>
      </div>
      </LoadingComponent>
  );
});

export default Booked;

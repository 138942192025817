import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { bookedRedx } from "../../helper/customSlice";
import { useParams } from "react-router-dom";
import LoadingComponent from "../loading/LoadingComponent";
import Images from "../../assets/icons";

function AddPeople({
  participants,
  isDropdownOpen,
  setIsDropdownOpen,
  filteredPeople,
  setFilteredPeople,
}) {
  const { facilityName } = useParams();
  const [inputValue, setInputValue] = useState("");
  const [searchPeople, setSearchPeople] = useState([]);
  // const [filteredPeople, setFilteredPeople] = useState([]);
  const [addPerson, setAddPerson] = useState([]);
  const [showFilteredPeople, setShowFilteredPeople] = useState([]);
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const inputRef = useRef(null);

  const facilities = useSelector(
    (state) => state?.facilities?.category?.data?.data
  );

  const facilityFilter = facilities?.filter(
    (facility) => facility?.name === facilityName
  );

  const peopleLists = useSelector(
    (state) => state?.users?.usersPeople?.data?.data
  );

  const loginUser = useSelector(
    (state) => state?.auth?.login?.data?.data?.user
  );
  const usersPeoples = peopleLists
    ?.filter((person) => person.id !== loginUser?.id)
    ?.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        !event.target.closest(".scrollbar-create")
      ) {
        setIsDropdownOpen(false);
        setInputValue("");
        setShowFilteredPeople(addPerson);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [addPerson]);

  const allUser = usersPeoples?.filter((person) =>
    person.name.toLowerCase().includes(inputValue)
  );

  const dropDownList = () => {
    if (addPerson?.length === 0) {
      setFilteredPeople(allUser);
    } else {
      const filtered = usersPeoples?.filter(
        (person) => !addPerson?.map((p) => p.id).includes(person.id)
      );
      setFilteredPeople(filtered);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e?.target.value;
    setInputValue(inputValue);

    if (inputValue === "") {
      setFilteredPeople(usersPeoples);
    } else {
      const filtered = usersPeoples?.filter((person) => {
        const personValue = (person.name || "").toString().toLowerCase();
        const inputLowerCase = inputValue.toLowerCase();

        return personValue.includes(inputLowerCase);
      });

      setFilteredPeople(filtered);
    }
  };

  const handleItemRemove = (person) => {
    const updatedItems = showFilteredPeople?.filter(
      (people) => people.id !== person.id
    );

    setShowFilteredPeople(updatedItems);
    setAddPerson(updatedItems);
  };

  const handleSearchPeople = (value) => {
    const result = allUser?.filter((person) =>
      person.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredPeople(result);
  };

  const handleSelectAll = (e) => {
    // setInputValue(e.target.value);
    setSelectAll((prevSelectAll) => !prevSelectAll);
    if (!selectAll) {
      setAddPerson(allUser);
      // setIsPersonAdded(true)
    } else {
      setAddPerson([]);
      // setIsPersonAdded(false)
    }
  };

  useEffect(() => {
    if (participants) {
      setAddPerson(participants);
      setShowFilteredPeople(participants);
    }
  }, [participants]);

  useEffect(() => {
    setFilteredPeople(allUser);
  }, [peopleLists]);

  useEffect(() => {
    dispatch(bookedRedx?.actions.setAddPeople(showFilteredPeople));
  }, [showFilteredPeople]);

  const handleSelectDropDownPerson = (person) => {
    const personAdded = addPerson.some((p) => p.id === person.id);
    if (!personAdded) {
      setAddPerson((prevList) => {
        if (!prevList.some((p) => p.id === person.id)) {
          return [...prevList, person];
        } else {
          return prevList;
        }
      });
    } else {
      setAddPerson((prevList) => prevList.filter((p) => p.id !== person.id));
    }

    if (selectAll) {
      setSelectAll(false);
    }
  };

  return (
    <>
      <section className="addPeopeContainer">
        <AiOutlineUsergroupAdd size={20} className="addGroup" />
        <input
          type="text"
          placeholder="Add People"
          tabIndex="-1"
          value={inputValue}
          onChange={handleInputChange}
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
            dropDownList();
          }}
        />
      </section>

      {/* dropdown list */}
      {/* {filteredPeople?.length > 0 && ( */}
      <div
        style={{ position: "relative", top: "-60px", zIndex: 10 }}
        ref={inputRef}
      >
        {isDropdownOpen && (
          <div className="scrollbar-create">
            <div className="close-btn" onClick={() => setIsDropdownOpen(false)}>
              <img src={Images.CloseIcon} alt="close" />
            </div>
            <div>
              <input
                type="text"
                // value={searchPeople}
                onChange={(e) => handleSearchPeople(e.target.value)}
                name=""
                placeholder="Search people"
                id=""
              />
            </div>
            <div
              className="user-list-box"
              // style={{
              //   maxHeight:
              //     facilityFilter[0]?.needLocation == 0 ? "130px" : "190px",
              // }}
            >
              <LoadingComponent loadingGroup={"u"} design="true">
                {inputValue == "" ? (
                  <div className="all-Box" onClick={(e) => handleSelectAll(e)}>
                    <div className="all-Img">
                      <figure className="all-Icon">A</figure>
                      <span>All</span>
                    </div>
                    <input
                      className="dropDownPeopleBox"
                      id="all-CheckBox"
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </div>
                ) : null}
                {filteredPeople?.map((person) => (
                  <div
                    className="dropDownPeopleList"
                    key={person.id}
                    onClick={(e) => handleSelectDropDownPerson(person)}
                  >
                    <div className="dropDownPeople">
                      <figure className="dropDownPeopleImg">
                        {person.name.split("").slice(0, 1)}
                      </figure>
                      <span className="dropDownPeopleName">{person.name}</span>
                    </div>
                    <input
                      className="dropDownPeopleBox"
                      type="checkbox"
                      checked={addPerson.some((p) => p.id === person.id)}
                      onChange={() => handleSelectDropDownPerson(person)}
                    />
                  </div>
                ))}
              </LoadingComponent>
            </div>
          </div>
        )}
      </div>

      {/* )} */}

      {/* show lists */}

      {
        // !isDropdownOpen &&
        showFilteredPeople?.length > 0 ? (
          <section
            className="showPeopleListsContainer"
            style={{
              position: isDropdownOpen && "relative",
              marginTop:
                isDropdownOpen && `${-60 - 46 * showFilteredPeople?.length}px`,
            }}
          >
            {showFilteredPeople?.map((person, key) => (
              <section
                className="addPerson"
                key={key}
                onClick={() => handleItemRemove(person)}
              >
                <article className="showPeopleContainer">
                  {person?.avatar_url !== null ? (
                    <figure className="shwoPeopleImgCustom">
                      {person.name.split("").slice(0, 1)}
                    </figure>
                  ) : (
                    <img src={person?.avatar_url} className="shwoPeopleImg" />
                  )}

                  <span className="shwoPeopleName">{person?.name}</span>
                </article>
                <IoIosClose className="shwoPeopleIcon" size={30} />
              </section>
            ))}
          </section>
        ) : null
      }
    </>
  );
}

export default AddPeople;

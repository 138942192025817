import React, { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function RouteProvider({ children }) {
  const token = useAuth();
  const route = useNavigate();
  const existToken = String(token).includes("Bearer");
  useEffect(() => {
    if (!existToken) {
      route("/");
    }
  }, [existToken]);
  return existToken && children;
}

import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { useSelector } from "react-redux";

// Style
import Icon from "../../assets/icons";
import SettingBox from "../../components/Popup/SettingBox";

const HomePage = () => {
  const navigate = useNavigate();
  const adminId = useSelector(
    (state) => state?.auth?.login?.data?.data?.user?.id
  );
  const category = useSelector(
    (state) => state?.facilities?.category?.data?.data
  );

  useEffect(() => {
    callApi("facilities/category").loadingGroup("c").executeDispatch();
  }, []);

  return (
    <div className="layout">
      <div className="container">
        <LoadingComponent loadingGroup={"c"}>
          <div className="home-page">
            <div className="meeting-head-box">
              <div className="record-title">
                <h2>Types of Facility</h2>
                {/* <button
                  className="save"
                  onClick={() => navigate(`/bookingRecord/${adminId}`)}
                >
                  My Bookings
                </button> */}
                <SettingBox />
              </div>
            </div>

            <div className="type-layout">
              {category?.map((facility, index) => (
                <button
                  className="type-card"
                  key={index}
                  onClick={() => navigate(`/${facility.name}`)}
                >
                  <img
                    src={facility.image}
                    alt={facility.name}
                    title={facility.name}
                  />
                  <h3>{facility.name}</h3>
                </button>
              ))}
            </div>
          </div>
        </LoadingComponent>
      </div>
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import MapPopUp from "./MapPopUp";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { PiMapPin } from "react-icons/pi";
import { bookedRedx } from "../../helper/customSlice";
import MapCreate from "../Popup/MapCreate";
import Modal from '@mui/material/Modal';
import { NewMapCreate } from "./NewMapCreate";
import LocalIcon from '../../assets/icons';

function LocationCreate({ address,showAddress,setShowAddress,...props }) {
  const dispatch = useDispatch();
  const [openMapCreate,setOpenMapCreate] = useState(false)
  const [addAddress,setAddAddress] = useState()  

  const getMapCreate = () =>{
    if(props?.setOpen){
      props?.setOpen(true)
    }
    props?.setOpenMap(true)
  }

  useEffect(()=>{
    if(address){
      setShowAddress(address)
    }
  },[address])

  useEffect(()=>{
    setShowAddress(prevData => {
      if (addAddress) {
          return [...prevData, addAddress];
      } else {
          return prevData;
      }
    });
  },[addAddress])

  useEffect(()=>{
    dispatch(bookedRedx?.actions.setCoord(showAddress));
  },[showAddress])

  const handelRemove = (key)=>{
    const removeAddress = showAddress?.filter((remAdd,index) => index !== key)
    setShowAddress(removeAddress)
  }

  const closeModal =()=>{
    props?.setOpenMap(false)
    setOpenMapCreate(false)
  }
  return (
    <>
      <div className="locationContainer">
        <div className="locationIcon">
          <MdOutlineAddLocationAlt size={20} />
        </div>

        <button className="locationButton" onClick={() => getMapCreate()}>
          <img src={LocalIcon.Add}/>
          <p>Add Location</p>
        </button>
      </div>
      {showAddress?.length > 0 ? (
        <div className="addressBox">
          {showAddress?.map((location, key) => {
            return (
              <div key={key} className="addressContainer">
                <div className="searchAddress">
                  <PiMapPin size={20} className="addressIcon" />
                  <div className="address">
                    <h5 className="address-name">{location?.name}</h5>
                    <p className="addres-detail">{location?.address}</p>
                  </div>
                  <IoIosClose
                    onClick={() => handelRemove(key)}
                    style={{
                      cursor: "pointer",
                    }}
                    size={20}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      <Modal
          open={props?.openMap || openMapCreate} 
          onClose={closeModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          className="map-modal"
          disableEnforceFocus
          autoFocus={false}
        >
          <>
            {
              props?.openMap?(
                <MapCreate setAddAddress={setAddAddress}
                           setOpenMap={props?.setOpenMap}
                           openMap={props?.openMap}
                           setOpenMapCreate = {setOpenMapCreate} />
              ) : null
            }
            {
              openMapCreate?(
                <NewMapCreate setAddAddress={setAddAddress}
                              setOpenMap={props?.setOpenMap}
                              setOpenMapCreate = {setOpenMapCreate} />
              ) : null
            }
          </>
        </Modal>
    </>
  );
}

export default LocationCreate;

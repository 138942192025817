import LoginPage from "../screens/auth/LoginPage";
import HomePage from "../screens/home/HomePage";
import MeetingRoomPage from "../screens/meeting_room/meetingRoom";
import FleetPage from "../screens/fleet/fleet";
import Booking from "../screens/booking_date/bookingDate";
import BookingRecords from "../screens/home/BookingRecords";
import ChangePasswordPage from "../screens/auth/ChangePassword";
import RouteProvider from "../provider/RouteProvider";
import AuthProvider from "../provider/AuthProvider";

export const RouteConfig = [
  {
    path: "/",
    element: (
      <AuthProvider>
        <LoginPage />
      </AuthProvider>
    ),
  },
  {
    path: "/change-password",
    element: (
      <RouteProvider>
        <ChangePasswordPage />
      </RouteProvider>
    ),
  },
  {
    path: "/homepage",
    element: (
      <RouteProvider>
        <HomePage />
      </RouteProvider>
    ),
    special_access: "user",
  },
  {
    path: "/bookingRecord/:userId",
    element: (
      <RouteProvider>
        <BookingRecords />
      </RouteProvider>
    ),
    special_access: "user",
  },
  // Meeting
  {
    path: "/:facilityName",
    element: (
      <RouteProvider>
        <MeetingRoomPage />
      </RouteProvider>
    ),
    special_access: "user",
  },
  {
    path: "/:facilityName/:roomId",
    element: (
      <RouteProvider>
        <Booking />
      </RouteProvider>
    ),
    special_access: "user",
  },
];

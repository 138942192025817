import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import { bookedRedx } from "../../helper/customSlice";
import Modal from '@mui/material/Modal';
import Booked from "../Booked/booked";
import Create from "../Popup/Create";
import Edit from "../Popup/Edit";
import Show from "../Popup/Show";
import MapCreate from "../Popup/MapCreate";

import timeList from '../../assets/public/time.json'

const DateList = ({ changeRoom, changeDate ,selectedDay,current_date,current_time}) => {
  const time = timeList?.time
  const [open, setOpen] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [popupId, setPopupId] = useState();
  const [createTime, setCreateTime] = useState({ fromTime: "12:00 am", toTime: "1:00 am" })
  const [createData,setCreateData] = useState()
  const [triggerTimeout, setTriggerTimeout] = useState(false)
  const [loadingCreate,setLoadingCreate] = useState(false)
  const [timeId,setTimeId] = useState()

  const dispatch = useDispatch();
  const bookedList = useSelector((state)=> state?.booked?.bookedListByDate?.data?.data)
  const bookedRedxList = useSelector((state) => state?.bookedRedx?.bookedRedxRequest?.info)
  
  // useEffect(() => {
  //   callApi("booked/bookedListByDate")
  //     .withKeyParameter({RoomId : changeRoom,
  //                        bookedListByDate:changeDate})
  //     // .loadingGroup("date")
  //     .executeDispatch();
  // },[changeDate]);

  
  useEffect(() => {
    bookedList?.forEach((list) => {
        if (list.status !== "cancelled") {
            if (!bookedRedxList || !bookedRedxList.some((redList) => redList.id === list.id)) {
              dispatch(bookedRedx?.actions.setData(list));
            }
            else if(bookedRedxList === null || Object.keys(bookedRedxList).length === 0){
              dispatch(bookedRedx?.actions.setData(list));
            }
        }
    });
}, [changeDate, bookedList, bookedRedxList]);

  useEffect(() => {
    if (createData) {
        setTimeout(() => {
            setTriggerTimeout(false)
        }, 3000);  
        setTriggerTimeout(true)       
    }
}, [createData]);


  const handleTouchStart = (e) => {
    // setIsDragging(true);
    const element = document.getElementsByClassName("date-layout")
    const bookCreateBtn = document.getElementsByClassName("book-create-container")
    let distanceFromTop = getY(e) + window.scrollY;
    let autoCreateTime = Math.floor((distanceFromTop - element[0].offsetTop - bookCreateBtn[0].offsetTop - 20) / 52)
    let autoCreateMinute = (distanceFromTop - element[0].offsetTop - bookCreateBtn[0].offsetTop - 20) % 52

    let startTime = Time(autoCreateTime, autoCreateMinute)
    let endTime = Time(autoCreateTime + 1, autoCreateTime === 23 ? 0 : autoCreateMinute)

    setCreateTime({ fromTime: startTime, toTime: endTime })
  }

  const Time = (time, minute) => {
    let returnTime;
    let unit = time == 24? ' am' :time > 11 ? ' pm' : ' am'
    if (time === 0) {
      time = 12
    }
    else if (time > 12) {
      time = time - 12
    }

    // Minute
    if (minute === 0 ) {
      minute = '00'
    }
    else if (minute < 21) {
      minute = 15
    }
    else if (minute < 34) {
      minute = 30
    }
    else if (minute < 47) {
      minute = 45
    }

    // Totla Time
    returnTime = `${time}:${minute}${unit}`
    return returnTime;
  }

  const getY = (event) => {
    if (event.touches && event.touches.length > 0 && event.touches[0].clientY !== "undefined") {
      const touch = event.touches[0];
      return gridMove(touch?.clientY);
    } 
    else if(event.changedTouches && event.changedTouches.length > 0 && event.changedTouches[0].clientY !== "undefined"){
      return event.changedTouches[0].clientY;
    }
    else {
      return gridMove(event.clientY);
    }
  }
  const gridMove = (position) => {
    position = (position / 13);
    return Math.round(position) * 13;
  }

  const openModal = () => {
    setOpen(true);
    setOpenShow(false);
    setOpenEdit(false);
    setOpenMap(false);
  };
  
  const closeModal = () => {
    setOpen(false);
    setOpenShow(false);
    setOpenEdit(false);
    setOpenMap(false);
  };

  return (
    <>
      <div className="date-layout"
        style={
          {
            height: `${(time.length * 52) + 26}px`
          }
        }>
        {/* Time List */}
        <ul className={changeDate === current_date ? "unavailable-ul":""}>
          {
            time?.map((time, index) => (
              <li key={index} 
                  className={(changeDate === current_date &&  current_time > index) ? "unavailable-li":"date-list"}
                  data-time = {time.time}>
                <label>{time.time}</label>
              </li>
            ))
          }
        </ul>

        {/* Vertical Line */}
        <div className="line" 
             style={{background:changeDate === current_date ? "#e0e0e0":""}}></div>

        {/* Booking Area */}
        <div className="book-create-container">
          <div
            className="book-crate-btn"
            onMouseDown={(e) => { handleTouchStart(e); openModal() }}
          >
          </div>
            
            {/* Booked Area*/}
            {bookedRedxList?.map((item, index) => (
              item.start_time !== undefined &&
                item.end_time !== undefined &&  
                item.facility_id.id === Number(changeRoom) &&     
                  item.book_date === changeDate && 
                  item.status !== "cancelled" &&
                    <Booked
                      key={index}
                      id={item?.id}
                      name={item?.title}
                      fromTime={item?.start_time}
                      toTime={item?.end_time}                    
                      note = {item?.note}
                      book_date= {item?.book_date}
                      facility_id= {item?.facility_id}
                      locations = {item?.locations}
                      book_by = {item?.book_by}
                      participants= {item?.participants}
                      approved_by = {item?.approved_by}
                      status={item?.status}
                      setCreateData = {setCreateData}
                      setOpen={setOpen}
                      setOpenShow={setOpenShow}
                      setPopupId={setPopupId}
                      loadingCreate={loadingCreate}
                      setLoadingCreate={setLoadingCreate}
                      timeId={timeId}
                    />
                    
            ))}
        </div>      
        <Modal
          open={open || openShow || openEdit }
          onClose={closeModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          disableEnforceFocus
          autoFocus={false}
        >
          <>
            {open ? (
              <Create
                setOpen={setOpen}
                setOpenMap={setOpenMap}
                openMap={openMap}
                fromTime={createTime.fromTime}
                toTime={createTime.toTime}
                book_date = {changeDate}
                facility_id = {changeRoom}
                setCreateData = {setCreateData}
                setLoadingCreate={setLoadingCreate}
                timeId={timeId}
                setTimeId={setTimeId}
              />
            ) : null}
            {openShow ? (            
              <Show
                popupId={popupId}
                setOpenShow={setOpenShow}
                openShow={openShow}
                setOpenEdit={setOpenEdit}
                openEdit={openEdit}
                setCreateData = {setCreateData}
              />
            ) : null}
            {
              openEdit?(
                <Edit 
                  popupId={popupId}
                  setOpenMap={setOpenMap}
                  openMap={openMap}
                  setOpenEdit={setOpenEdit}
                  openEdit={openEdit}
                  setCreateData = {setCreateData} />
              ) : null
            }
          </>
        </Modal>

      </div>
      {/* Booked Message */}
      {
        createData && triggerTimeout &&
          <div className="message" 
            style={
              {
                background:createData?.status === false || !createData?.message ? '#f8e7e7':'#05445E',
                color:createData?.status === false || !createData?.message ? '#05445E':'#fff',
              }
            }>
            {createData?.message? createData.message:createData}
          </div>
      }
      
    </>
  )
}

export default DateList;
import React, { useEffect, useRef, useState } from "react";
import { bookedRedx } from "../../helper/customSlice";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import LocalIcon from '../../assets/icons';

const MapCreate = ({setAddAddress,setOpenMap,openMap,setOpenMapCreate,...props }) => {
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const [location, setLocation] = useState();
  const [inputValue, setInputValue] = useState('');
  const [filterLocation,setFilterLocation] = useState([])
  
  const dispatch = useDispatch();

  const locationList = useSelector((state) => state?.bookedRedx?.bookedRedxRequest?.location)
  
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB7fbPdevYiD3SB3wmlvZ36ubTH3Y--qGE&libraries=places`;
        script.defer = true;
        script.async = true;

        script.onload = () => {
          if (window.google) {
            initMap();
          } else {
            console.error(
              "Google Maps script loaded, but window.google is not defined."
            );
          }
        };

        document.head.appendChild(script);
      } else {
        initMap();
      }
    };
    let openInfoWindow= null
    const initMap = () => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 16.8409, lng: 96.1735 },
        zoom: 15,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
      });

      const input = document.getElementById("addressSearch");
      const searchBox = new window.google.maps.places.SearchBox(input);
      
      searchBox.addListener("places_changed", () => {
        const filterValue = handleSubmit(input.value)
        const places = searchBox.getPlaces();        
        const filterValueCoords = filterValue?.map(item => ({ latitude: Number(item.latitude).toFixed(7), longitude: Number(item.longitude).toFixed(7) }));
               
        if (places.length === 0) {
          return;
        }

        clearMarkers();
        
        const bounds = new window.google.maps.LatLngBounds();
        places.forEach((place) => {
          if (!place.geometry) {
            // console.log("Returned place contains no geometry");
            return;
          }
          const filterCoords = filterValueCoords?.some(filter => filter.latitude === place.geometry.location.lat().toFixed(7) && 
          filter.longitude === place.geometry.location.lng().toFixed(7))
          
          if(!filterCoords){
            const marker = new window.google.maps.Marker({
              map,
              title: place.name,
              position: place.geometry.location,
              // icon: LocalIcon.Map
            });
            marker.addListener("click", (event) => {
              if (openInfoWindow) {
                openInfoWindow.close();
               }
              const lat = event.latLng.lat();
              const lng = event.latLng.lng();
              const geocoder = new window.google.maps.Geocoder();            
              geocoder.geocode({ location: event.latLng }, (results, status) => {
                if (status === "OK" && results[0]) {
                  const name = place.name;
                  const address = results[0].formatted_address;
                  const data = { name : name,address: address,latitude: lat, longitude: lng   };
                  setLocation(data);
                  const ContentString = `<div class="infoWindowStyle">
                                          <h3>${name}</h3>
                                          <h5>${address}</h5>         
                                        </div>`;
  
                  const contentBox = document.createElement("div");
                  contentBox.style.width = "200px";
                  contentBox.innerHTML = ContentString;
                  
                  
                  const infowindow = new window.google.maps.InfoWindow({
                    content: contentBox,
                  });
                  infowindow.setPosition(event.latLng);
                  infowindow.open(map);
  
                  // close for infowindow 
                  openInfoWindow = infowindow;
                } else {
                  console.error("Geocoder failed due to: " + status);
                }
              });
            });
            markersRef.current.push(marker);
          }

          

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        if(filterValue){
          filterValue?.forEach((place)=>{
            const filterMarker = new window.google.maps.Marker({
              map,
              title: place.name,
              position: new window.google.maps.LatLng(place.latitude, place.longitude),
              icon: LocalIcon.Map
            });

            filterMarker.addListener("click", (event) => {
              if (openInfoWindow) {
                openInfoWindow.close();
               }
              const lat = event.latLng.lat();
              const lng = event.latLng.lng();
              // const geocoder = new window.google.maps.Geocoder();            
              // geocoder.geocode({ location: event.latLng }, (results, status) => {
              //   if (status === "OK" && results[0]) {
                  const name = place.name;
                  const address = place.address;
                  const data = { name : name,address: address,latitude: lat, longitude: lng   };
                  setLocation(data);
                  const ContentString = `<div class="infoWindowStyle">
                                          <h3>${name}</h3>
                                          <h5>${address}</h5>         
                                        </div>`;
  
                  const contentBox = document.createElement("div");
                  contentBox.style.width = "200px";
                  contentBox.innerHTML = ContentString;
                  
                  
                  const infowindow = new window.google.maps.InfoWindow({
                    content: contentBox,
                  });
                  infowindow.setPosition(event.latLng);
                  infowindow.open(map);
  
                  // close for infowindow 
                  openInfoWindow = infowindow;
                // } else {
                //   console.error("Geocoder failed due to: " + status);
                // }
              // });
            });
  
            markersRef.current.push(filterMarker);
            
          })
        }
        map.fitBounds(bounds);
      });

    };

    const clearMarkers = () => {
      markersRef.current.forEach((marker) => {
        marker.setMap(null);
      });

      markersRef.current = [];
    };

    loadGoogleMapsScript();
  }, []);

  const handelInput =(e)=>{
    setInputValue(e?.target?.value)
  }

  const handleSubmit = (value) => {
      if(locationList){
        const filter=(locationList?.filter((location) => {
          const locationName = (location?.name || "").toString().toLowerCase();
          const inputLowerCase = value?.toLowerCase();
          return locationName?.includes(inputLowerCase);
        }))        
        return filter
      }
  };
 
  const saveLocation = () => {
    setOpenMap(false)
    setAddAddress(location);
  };

  const handelNewMap = () =>{
    setOpenMap(false)
    setOpenMapCreate(true)
  }

  return (
    <Box className="map-box">
      <div className="date-modal-map">
          <input
            id="addressSearch"
            className="addressInputCreate"
            type="text"
            placeholder="Search your location"
            value={inputValue}
            onChange={handelInput}
            // onKeyDown={handleSubmit}
          />
        <div
          id="mapContainer"
          ref={mapRef}
        ></div>
        <div className="CreateButtonContainer">
          <button className="save" onClick={() => handelNewMap()}>
              New Location
          </button>
          <div className="footer-right">
            <button className="cancel" onClick={() => setOpenMap(false)}>
              Cancel
            </button>
            <button className={location? "save":"disable"} onClick={() => saveLocation()} disabled={location? false:true}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default MapCreate;

import { res_modifiers_config } from "../../helper/res_modifiers_config";

// Define the API URLs for different environments
export const API_URLs = {
  production: "https://mrbooking.innovixdigital.com",
  development: "https://mrbooking.innovixdigital.com",
};

export const endpoints = Object.freeze({
  auth: {
    login: {
      method: "post",
      endpoint: "api/login",
      // res_modifier: res_modifiers_config.getAccessToken,
      // token: "optional", // require, optional
      // expire_in: 10
    },
    change_password: {
      method: "post",
      endpoint: "api/change-password",
      token: "require",
      // res_modifier: res_modifiers_config.getAccessToken,
      // token: "optional", // require, optional
      // expire_in: 10
    },
  },
  users: {
    usersPeople: {
      method: "get",
      endpoint: "api/users",
      token: "require",
    },
    admin: {
      method: "get",
      endpoint: "api/users/{:userId}",
      token: "require",
    },
  },
  facilities: {
    category: {
      method: "get",
      endpoint: "api/categories",
      token: "require",
    },
    facilityByCat: {
      method: "get",
      endpoint: "api/facilityByCat/{:facilityName}",
      token: "require",
    },
    facilityByRoomId: {
      method: "get",
      endpoint: "api/facilities/{:facilityByRoomId}",
      token: "require",
    },
  },
  booked: {
    bookedList: {
      method: "get",
      endpoint: "api/bookingListByFacility/{:RoomId}",
      token: "require",
    },
    bookedListByUser: {
      method: "get",
      endpoint: "api/bookingListByUser/{:UserId}",
      token: "require",
    },
    bookedListByDate: {
      method: "get",
      endpoint: "api/bookingList/{:RoomId}/{:bookedListByDate}",
      token: "require",
    },
    bookedRequest: {
      method: "post",
      endpoint: "api/bookRequest",
      token: "require",
    },
    bookedData: {
      method: "get",
      endpoint: "api/bookRequest/{:bookedId}",
      token: "require",
    },
    bookedUpdate: {
      method: "put",
      endpoint: "api/bookRequest/{:bookedId}",
      token: "require",
    },
    bookedCancel: {
      method: "delete",
      endpoint: "api/bookRequest/{:bookedId}",
      token: "require",
    },
    bookedApprove: {
      method: "post",
      endpoint: "api/bookingStatus/{:bookedId}",
      token: "require",
    },
  },
  location: {
    locationList: {
      method: "get",
      endpoint: "api/locations",
      token: "require",
    },
    locationById: {
      method: "get",
      endpoint: "api/locations/{:bookedId}",
      token: "require",
    },
  },
  date: {
    holidays: {
      method: "get",
      endpoint: "api/holidays",
      token: "require",
    },
  },

  // others: {
  //   otherIn: {
  //     method: "get",
  //     endpoint: "api/auth/token",
  //   },
  //   faceBookSignIn: {
  //     method: "get",
  //     endpoint: "api/auth/facebook",
  //   },
  // },

  persistSlice: {},
});

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function AuthProvider({ children }) {
  const token = useAuth();
  const route = useNavigate();
  useEffect(() => {
    if (String(token).includes("Bearer")) {
      route("/homepage");
    }
  }, [token]);
  return children;
}

import React, { forwardRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import Box from "@mui/material/Box";
import { MdOutlineTitle, MdMoreTime,MdDateRange,MdCategory  } from "react-icons/md";
import AddPeople from "./AddPeople";
import timeList from "../../assets/public/time.json";
import LocationCreate from "./LocationCreate";
import LoadingComponent from "../loading/LoadingComponent";
import { useParams } from "react-router-dom";
import { bookedRedx } from "../../helper/customSlice";

// Date picker
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const dateFormat = (time) =>{
  const parsedTime = dayjs(time?.toUpperCase(), 'hh:mm A');
  return dayjs(new Date()).hour(parsedTime.hour()).minute(parsedTime.minute());
}

const Edit = forwardRef(({ setOpenEdit,popupId ,...props}, ref) => {
  const {facilityName} = useParams();
  const [title, setTitle] = useState();
  const [note, setNote] = useState("");
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();  
  const [selectedDate, setSelectedDate] = useState();
  const [facility,setFacility] = useState()
  const [showAddress,setShowAddress] = useState([])

  const [mentionPerson, setMentionPerson] = useState();  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredPeople, setFilteredPeople] = useState([]);

  const dispatch = useDispatch();
  const category = useSelector(
    (state) => state?.facilities?.category?.data?.data
  );
  const roomsList = useSelector(
    (state) => state?.facilities?.facilityByCat?.data?.data
  );
  const bookedDetail = useSelector((state)=> state?.booked?.bookedData?.data)
  const coord = useSelector((state) => state?.bookedRedx?.bookedRedxRequest?.coord);
  const coordList = category?.some((cats) => cats.name === facilityName && cats.needLocation === 1)
  const participants = useSelector(
    (state) => state?.bookedRedx?.bookedRedxRequest?.participants
  );
  const peopleLists = useSelector(
    (state) => state?.users?.usersPeople?.data?.data
  );
  useEffect(()=>{
    callApi("booked/bookedData")
      .withKeyParameter({bookedId : popupId})
      .loadingGroup("s")
      .executeDispatch();
    setTitle(bookedDetail?.title)
    setNote(bookedDetail?.note)
    setFacility(bookedDetail?.facility_id?.id)
    setSelectedDate(dayjs(bookedDetail?.book_date))
    setStartTime(handelTimeFormat(bookedDetail?.start_time))
    setEndTime(handelTimeFormat(bookedDetail?.end_time))
  },[])

  // useEffect(() => {
  //   if(roomsList?.length === undefined){
  //     callApi("facilities/facilityByCat")
  //       .withKeyParameter({facilityName:facilityName})
  //       .loadingGroup("c")
  //       .executeDispatch();
  //   }
  // }, [roomsList]);

  const handelTimeFormat = (Time) =>{
    var regex = /^(\d{1,2}):(\d{2})\s*([apAP][mM])$/;
    var startMatch =Time?.match(regex);

    var hour = parseInt(startMatch[1], 10);
    var minute = parseInt(startMatch[2], 10);
    var period = startMatch[3].toLowerCase()

    minute = minute < 10 ? "0" + minute.toString() : minute;

    const time = `${hour}:${minute} ${period}`
    return time
  }
  

  useEffect(() => {
    if (peopleLists?.length === undefined) {
      callApi("users/usersPeople")
      .executeDispatch();
    }
  }, [peopleLists]);

  

  const dataUpdate = () => {
    setOpenEdit(false);
    const participantsId = participants?.filter((filter) => filter.id).map((participant) => participant.id);
    const updateData = {
        title: title,
        note: note? note : '',
        book_date: dayjs(selectedDate.$d).format('YYYY-MM-DD'),
        start_time: startTime,
        end_time: endTime,
        facility_id: Number(facility),
        locations: coordList? coord : [],
        book_by: bookedDetail?.book_by?.id,
        participants: participantsId
    }
    // if(updateBooked !== undefined){
      callApi("booked/bookedUpdate")
      .withKeyParameter({bookedId:popupId})
      .withBody(updateData)
      .loadingGroup("up"+popupId)
      .executeDispatch()
      .then((res)=>{
        props.setCreateData(res)
        if(res.data !== undefined){
          dispatch(bookedRedx?.actions.updateData([res.data]));
        }        
      }); 
    // }
  };

  const timeArr = timeList?.detailTime;
  const selectStartTime = dateFormat(startTime)
  const selectEndTime = dateFormat(endTime)

  return (
    <>
      <div ref={ref} className="createContainer">
        <Box className="date-modal-create" tabIndex="-1">
        <LoadingComponent loadingGroup={"s"} design="true">
          <div className="scrollContainer">
            <div className="bodyContainer">
              <div className="create-edit-container">
                <MdOutlineTitle size={23}/>
                <input
                  type="text"
                  placeholder="Add Title"
                  name="book-title"
                  tabIndex="-1"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              {/* Date */}
              <div className="create-edit-container">
                <MdDateRange size={23}/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Choose Date" 
                                value={selectedDate}
                                onChange={(newDate) => {
                                    setSelectedDate(newDate);
                                }}

                                />
                    {/* <input type="hidden" name="datePickerHidden" value={dayjs(selectedDate.$d).format('YYYY-MM-DD')} /> */}
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              {/* Time */}
              <div className="create-edit-container">
                <MdMoreTime size={23} />
                <div className="timeBox">
                  <select
                    defaultValue={startTime}
                    className="custom-dropdown"
                    onChange={(e) => setStartTime(e.target.value)}
                  >
                    {timeArr?.map((time, index) => (
                      <option
                        value={`${time.time}:${time.minute} ${time.period}`} 
                        key={index}
                      >
                        {time.time}:{time.minute} {time.period}
                      </option>
                    ))}
                  </select>

                  <label>-</label>
                  <select
                    defaultValue={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  >
                    {timeArr.map((time, key) => (
                      <option
                        value={`${time.time}:${time.minute} ${time.period}`}
                        key={key}
                      >
                        {time.time}:{time.minute} {time.period}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {selectStartTime > selectEndTime ? (
                <label className="error">The end time should be greater than the start time.</label>
              ) : null}

              {/* Room or Car */}
              <div className="create-edit-container">
                <MdCategory size={23} />
                <select defaultValue={facility}
                        onChange={(e)=> setFacility(e.target.value)}>
                    {
                      roomsList?.map((room,index)=>
                      <option value={room.id} key={index}>{room.name}</option>
                      )
                    }                  
                  </select>
              </div>

              {/* add people */}
              <section>
                <AddPeople setMentionPerson={setMentionPerson}
                          participants = {bookedDetail?.participants}
                          isDropdownOpen={isDropdownOpen}
                          setIsDropdownOpen ={setIsDropdownOpen}
                          filteredPeople={filteredPeople}
                          setFilteredPeople={setFilteredPeople} />
              </section>

              {/* Location */}
              {
                coordList &&
                  <LocationCreate address = {bookedDetail?.locations}
                                  showAddress={showAddress}
                                  setShowAddress={setShowAddress}
                                  setOpenMap={props?.setOpenMap}
                                  openMap={props?.openMap}/>
              } 
              {
                (isDropdownOpen && (filteredPeople?.length === undefined || filteredPeople?.length === peopleLists?.length - 1)) ? 
                <div style={{marginTop:'-80px'}}>
                  <h4 className="desStyle">Description</h4>
                  <textarea
                    className="book-description"
                    placeholder="add description"
                    // style={{marginTop:'10px'}}
                    onChange={(e) => setNote(e.target.value)}    
                  />
                </div>:
                <>
                  <h4 className="desStyle">Description</h4>
                  <textarea
                    className="book-description"
                    placeholder="add description"
                    // style={{marginTop:'10px'}}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </>
              } 
            </div>
          </div>
          <div className="create-footer">
            <div className="chat-create">
              {/* <button className="save" onClick={() => setOpenEdit(false)}>
                Chat
              </button> */}
            </div>
            <div className="footer-right">
              <button
                className="cancel"
                onClick={() => setOpenEdit(false)}
              >
                Cancel
              </button>
              <button className="save" onClick={()=>dataUpdate()}>
                Update
              </button>
            </div>
          </div> 
          </LoadingComponent>
        </Box>
      </div>
    </>
  );
});

export default Edit;

import React, { forwardRef, useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import Box from "@mui/material/Box";
import Button from "../button";
import { bookedRedx } from "../../helper/customSlice";
import { MdOutlineTitle, MdMoreTime } from "react-icons/md";
import AddPeople from "./AddPeople";
import timeList from '../../assets/public/time.json';
import LocationCreate from './LocationCreate'
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const dateFormat = (time) =>{
  const parsedTime = dayjs(time?.toUpperCase(), 'hh:mm A');
  return dayjs('2000-01-01').hour(parsedTime.hour()).minute(parsedTime.minute());
}

const Create = forwardRef(({ setOpen ,...props}, ref) => {
  const {facilityName} = useParams()
  const [title, setTitle] = useState();
  const [des, setDes] = useState("");
  const [startTime, setStartTime] = useState(props.fromTime.toUpperCase());
  const [endTime, setEndTime] = useState(props.toTime.toUpperCase());
  const [localArr,setLocalArr] = useState(false)
  const [showAddress,setShowAddress] = useState([])
  
  const [mentionPerson, setMentionPerson] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredPeople, setFilteredPeople] = useState([]);

  const dispatch = useDispatch();
  const category = useSelector(
    (state) => state?.facilities?.category?.data?.data
  );
  const locationList = useSelector((state) => state?.location?.locationList?.data?.data)
  const locationListRexd = useSelector((state) => state?.bookedRedx?.bookedRedxRequest?.location)
  const coord = useSelector((state) => state?.bookedRedx?.bookedRedxRequest?.coord);
  const coordList = category?.some((cats) => cats.name === facilityName && cats.needLocation === 1)

  const peopleLists = useSelector(
    (state) => state?.users?.usersPeople?.data?.data
  );
  const participants = useSelector(
    (state) => state?.bookedRedx?.bookedRedxRequest?.participants
  );
  const participantsOfIds = participants?.map((item) => item.id);
    
  // Admin
  const adminId = useSelector((state)=> state?.auth?.login?.data?.data?.user?.id)
  const participantList = participantsOfIds && participantsOfIds.length > 0 ? [adminId, ...participantsOfIds] : [adminId];

  function formatTime(timeString) {
    const [hours, minutes, meridiem] = timeString.split(/:|\s/);
    let formattedHours = hours.padStart(2, '0'); // Pad with leading zero if necessary
    return `${formattedHours}:${minutes} ${meridiem.toUpperCase()}`;
  }
  const dataSave = () => {  
    const formattedTime = formatTime(startTime);
    props?.setTimeId(formattedTime)
    if(coordList){
      if( title === null || title === undefined || title === ""){
        setTitle("")
      }
      if(coord.length === 0){
        setLocalArr(true)
      }
      if((title !== null && title !== undefined && title !== "") && (coord.length !== 0)){
        setOpen(false);
        props?.setLoadingCreate(true)
        const createData = {      
          title: title,
          note: des,
          book_date: props.book_date,
          start_time: startTime,
          end_time: endTime,
          facility_id: Number(props.facility_id),   
          locations: coordList? coord : [],
          book_by: adminId,
          participants: participantList
        }
        dispatch(bookedRedx?.actions.setLocationList([...locationListRexd,...coord]))    
        callApi("booked/bookedRequest")
          .withBody(createData)
          .loadingGroup("f")
          .executeDispatch()          
          .then((res)=>{
            props.setCreateData(res)
            if(res.data !== undefined){
              dispatch(bookedRedx?.actions.setData(res.data));
            }        
          });
  
        setTitle(title)
        setLocalArr(false)
      }
    }    
    else{
      if( title === null || title === undefined || title === "" ){
        setTitle("")
      }
      else if(title !== null && title !== undefined && title !== ""){
        setOpen(false);
        props?.setLoadingCreate(true)
        const createData = {      
          title: title,
          note: des,
          book_date: props.book_date,
          start_time: startTime,
          end_time: endTime,
          facility_id: Number(props.facility_id),   
          locations: coordList? coord : [],
          book_by: adminId,
          participants: participantList
        }    
        callApi("booked/bookedRequest")
          .withBody(createData)
          .loadingGroup("f")
          .executeDispatch()          
          .then((res)=>{
            props.setCreateData(res)
            if(res.data !== undefined){
              dispatch(bookedRedx?.actions.setData(res.data));
            }        
          });
  
        setTitle(title)
      }
    }
  };

  useEffect(() => {
    if (peopleLists?.length === undefined) {
      callApi("users/usersPeople")
      .loadingGroup("u")
      .executeDispatch();
    }
  }, [peopleLists]);

  useEffect(() => {
    if (locationList?.length === undefined) {
      callApi("location/locationList")
      .executeDispatch()
      .then((res)=>{
        if(res?.data){
          dispatch(bookedRedx?.actions.setLocationList(res.data))
        }
      })
    }
  }, [locationList]);

  useEffect(()=>{
    if(showAddress){
      setLocalArr(false)
    }
    else{
      setLocalArr(false)
    }
  },[showAddress])

  const timeArr = timeList?.detailTime
  // const selectStartTime = new Date(`2000-01-01 ${startTime}`)
  const selectStartTime = dateFormat(startTime).toDate()
  const selectEndTime = dateFormat(endTime).toDate()
 
  return (
    <>
      <div ref={ref} className="createContainer">
        <Box className="date-modal-create" tabIndex="-1">
        <div className="scrollContainer">
          <div className="bodyContainer">
            <div className="titleContainer">
              <MdOutlineTitle size={23} className="createTitle" />
              <input
                type="text"
                autoComplete="off"
                placeholder="Add Title"
                name="book-title"
                tabIndex="-1"
                value={title}
                // value={selectStartTime.toDate()}
                onChange={(e) => setTitle(e?.target.value)}
              />
            </div>
            
            {title === "" ? (
                <label className="error">Booking title is required.</label>
              ) : null}

            {/* Time */}
            <div className="time-layout">
              <div className="timeIcon">
                <div htmlFor="time">
                  <MdMoreTime size={21} />
                </div>
              </div>
              <div className="timeBox">
                <select    
                  defaultValue={props.fromTime}          
                  className="custom-dropdown"
                  onChange={(e) => setStartTime(e.target.value)}
                >
                  {timeArr?.map((time, index) => (
                    <option value={`${time.time}:${time.minute} ${time.period}`} 
                            key={index}>
                      {time.time}:{time.minute} {time.period}
                    </option>
                  ))}
                </select>

                <label>-</label>
                <select
                  defaultValue={props.toTime} 
                  onChange={(e) => setEndTime(e.target.value)}
                >
                  {timeArr.map((time, key) => (
                    <option value={`${time.time}:${time.minute} ${time.period}`} 
                            key={key}>
                      {time.time}:{time.minute} {time.period}
                    </option>
                  ))}
                </select>
              </div>
            </div> 
            {selectStartTime > selectEndTime ? (
                <label className="error">The end time should be greater than the start time.</label>
              ) : null}         

              {/* add people */}
              <section>
                <AddPeople  setMentionPerson={setMentionPerson} 
                            isDropdownOpen={isDropdownOpen}
                            setIsDropdownOpen ={setIsDropdownOpen}
                            filteredPeople={filteredPeople}
                            setFilteredPeople={setFilteredPeople}/>
              </section>

              {/* Location */}
              {
                coordList &&
                ((isDropdownOpen && (filteredPeople?.length === undefined || filteredPeople?.length === peopleLists?.length - 1))  ? 
                  <div style={{marginTop:'-80px'}}>
                    <LocationCreate showAddress={showAddress}
                                    setShowAddress={setShowAddress}
                                    setOpen = {setOpen}
                                    setOpenMap={props?.setOpenMap}
                                    openMap={props?.openMap}
                                    />
                    { localArr? (
                      <label className="error">Location is required.</label>
                    ) : null}
                  </div>:
                  <div style={{marginTop:'0px'}}>
                    <LocationCreate showAddress={showAddress}
                                    setShowAddress={setShowAddress}
                                    setOpen = {setOpen}
                                    setOpenMap={props?.setOpenMap}
                                    openMap={props?.openMap}
                                    />
                    { localArr? (
                      <label className="error">Location is required.</label>
                    ) : null}
                  </div>)
              }  
              {
                (isDropdownOpen && (filteredPeople?.length === undefined || filteredPeople?.length === peopleLists?.length - 1))  ? 
                <div style={{marginTop:`${coordList? '0px':'-80px'}`}}>
                  <h4 className="desStyle">Description</h4>
                  <textarea
                    className="book-description"
                    placeholder="add description"
                    style={{marginTop:'10px'}}
                    onChange={(e) => setDes(e.target.value)}
                  />
                </div>:
                <div style={{marginTop:'0px'}}>
                  <h4 className="desStyle">Description</h4>
                  <textarea
                    className="book-description"
                    placeholder="add description"
                    style={{marginTop:'10px'}}
                    onChange={(e) => setDes(e.target.value)}
                  />
                </div>
              }
              
            </div>
        </div>        
        <div className="create-footer">
          <div className="chat-create">
            {/* <Button className={"save"} onClick={() => setOpen(false)}>
              Chat
            </Button> */}
          </div>
          <div className="footer-right">
            <button className={"cancel"} onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button className={title? "save":"disable"} onClick={dataSave} disabled={title? false:true}>
              Save
            </button>
          </div>
        </div>
        </Box>
      </div>
    </>
  );
});

export default Create;

import React from 'react'
import dayjs from 'dayjs';

const BookedRecord = ({title,icon,needLocation,name,book_date,startTime,endTime,status}) => {
  const date = dayjs(book_date).format('ddd D MMMM')

  return (
    <>
        <div className='record-img'>
          <img src={icon} alt={title}/>          
        </div>
        <div className='record-detail-con'>
            <p className='date'>{date} ({startTime} - {endTime})</p>
            <h3>{title} | {name}</h3>
            <p className='status'>{status}</p>
        </div>
    </>
  )
}

export default BookedRecord;
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";

import Icon from "../../assets/icons";

const validationSchema = Yup.object().shape({
  userName: Yup.string().required("User name is required"),
  password: Yup.string()
    .min(3, "Password must be at least 3 characters long")
    .required("Password is required"),
});

const LoginPage = () => {
  const [result, setResult] = useState("");
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      setTimeout(() => {
        callApi("auth/login")
          .withBody({
            email: values.userName,
            password: values.password,
          })
          .loadingGroup("p")
          .executeDispatch()
          .then((res) => {
            if (res) {
              setResult(res.message);
              actions.setSubmitting(false);
              actions.resetForm();
            }
          });
      }, 100);
    },
  });

  return (
    <div className="layout">
      <div className="container">
        <form onSubmit={formik.handleSubmit} className="login-container">
          <img src={Icon.Logo} alt="Logo" title="Logo" />
          <h2 className="title">Welcome!</h2>
          <input
            type="text"
            name="userName"
            placeholder="Enter user name"
            value={formik.values.userName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.userName && formik.errors.userName ? (
            <label className="error">{formik.errors.userName}</label>
          ) : null}
          <input
            type="password"
            name="password"
            placeholder="Enter password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <label className="error">{formik.errors.password}</label>
          ) : null}
          <button
            type="submit"
            className="login-btn"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? "Logging in..." : "Login"}
          </button>
          {result && <p style={{ color: "red" }}>{result}</p>}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
